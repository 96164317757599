<template>
	<div class="out">
		<div class="header">
			<div class="logo Title">

				<div class="Chinese"><img src="../assets/img/return_index.png" class="return1" alt=""
						@click="$router.back()" />{{ $t('main.collinstedit') }} <span v-show="is_cg" style="color:#999">{{
							$t('main.draft') }}</span></div>
			</div>
			<div class="right-icon">
				<img src="../assets/img/logo-icon.png" alt="">
				<div class="cc-wc">
					{{$t('main.titleCenter')}}
				</div>
			</div>
		</div>
		<div class="content">
			<div class="zb-edit">
				<div class="zb-pic zb-edit-item">

					<div class="zb-edit-item-top ds-pic">
						<div class="title-ltfe">
							<div class="ds-left-icon">
								<img src="../assets/img/ds-bj-icon1.png" alt="">
								收藏机构相片
							</div>
							<div class="title-right">{{ $t('main.uilt') }}
								<span class="red">{{ $t('main.required') }}</span>
							</div>
						</div>

						<div class="">
							<div class="sc-btn">

								<div class="sc-btn-item" @click="upLoadImg">
									<div v-if="!ds_data.pic">+</div>
									<img v-else :src="showPic.url" alt="" />
									<div v-show="showPic.status == 'loading'" class="loading_i">
										<van-loading type="spinner" color="#fff" size="0.24rem" vertical>{{
											$t('main.uploading') }}</van-loading>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="zb-pic zb-edit-item">
						<div class="zb-edit-item-top">
							<div class="title-ltfe">
								<div class="ds-left-icon">
									<img src="../assets/img/ds-bj-icon2.png" alt="">
									收藏机构名称
								</div>
							</div>
							<div class="title-right">&lt;10{{ $t('main.l20cc') }};<span class="red">{{ $t('main.required')
							}}</span></div>
						</div>
						<div class="zb-edit-item-bottom">
							<input type="text" maxlength="10" @keyup="ds_data.name = ds_data.name.replace(/^\s+/g, '')"
							placeholder="请输入收藏机构名称" v-model="ds_data.name" />
						</div>
						<div class="err" v-show="key_test == 'name'">{{ $t('main.csenword') }}{{ mgc }}</div>
					</div>
					

					<!-- <div class="zb-pic zb-edit-item">
						<div class="zb-edit-item-top">
							<div class="title-ltfe">
								<div class="ds-left-icon">
									<img src="../assets/img/ds-bj-icon3.png" alt="">
									收藏机构称号
								</div>
							</div>
							<div class="title-right">&lt;20{{ $t('main.l20cc') }};<span class="red">{{ $t('main.required')
							}}</span></div>
						</div>
						<div class="zb-edit-item-bottom">
							<input type="text" maxlength="20" @keyup="ds_data.title = ds_data.title.replace(/^\s+/g, '')"
								placeholder="请输入收藏机构称号" v-model="ds_data.title" />
						</div>
						<div class="err" v-show="key_test == 'title'">{{ $t('main.csenword') }}{{ mgc }}</div>
					</div> -->
					<div class="zb-pic zb-edit-item">
						<div class="zb-edit-item-top">
							<div class="title-ltfe">
								<div class="ds-left-icon">
									<img src="../assets/img/masterinfo-birthday.png" alt="">
									{{$t('main.createTime')}}
								</div>
							</div>
							<div class="title-right"><span class="red">{{$t('main.required')}}</span></div>
						</div>
						<div class="zb-edit-item-bottom birthday-item">
							<div @click="changeDate" class="shijian">
								<div>{{ds_data.birthday}}</div><img src="../assets/img/selected-bottom.png" alt="">
							</div>
							
						</div>
						<div class="err" v-show="key_test == 'birthday'">{{$t('main.csenword')}}{{ mgc }}</div>
					</div>

					<div class="zb-pic zb-edit-item">
						<div class="zb-edit-item-top">
							<div class="title-ltfe">
								<div class="ds-left-icon">
									<img src="../assets/img/ds-bj-icon4.png" alt="">
									收藏机构简介
								</div>
							</div>
							<div class="title-right">&lt;1000{{ $t('main.l20cc') }};<span class="red">{{ $t('main.required')
							}}</span></div>
						</div>
						<div class="zb-edit-item-bottom">
							<textarea v-model="ds_data.content"
								@keyup="ds_data.content = ds_data.content.replace(/^\s+/g, '')"
								:placeholder="$t('main.pbintrotcoll')" maxlength="1000"></textarea>
						</div>
						<div class="err1" v-show="key_test == 'content'">{{ $t('main.csenword') }}{{ mgc }}</div>
					</div>
					<div class="zb-pic zb-edit-item">
						<div class="zb-edit-item-top">
							<div class="title-ltfe">
								<div class="ds-left-icon test-edit-list">
									<img src="../assets/img/ds-bj-icon5.png" alt="">
									收藏机构荣誉
								</div>
							</div>
							<div class="title-right">
								{{ $t('master.honor20') }}
							</div>
						</div>
						<div class="zb-edit-item-bottom">
							<textarea rows="100" cols="20" @keyup="ds_data.honor = ds_data.honor.replace(/^\s+/g, '')"
							placeholder="请输入收藏机构荣誉" v-model="ds_data.honor"></textarea>
						</div>
						<div class="err1" v-show="key_test == 'honor'">{{ $t('main.csenword') }}{{ mgc }}</div>
					</div>

					<div class="zb-pic zb-edit-item">
						<div class="zb-edit-item-top">
							<div class="title-ltfe">
								<div class="ds-left-icon test-ds-left">
									<img src="../assets/img/certificate.png" alt="">
									收藏机构荣誉证书
								</div>
							</div>
							<div class="title-right">
								{{ $t('master.upce') }}
							</div>
						</div>
						<div class="creti-box" v-for="(item, index) in ds_data.certificate_info" :key="index">
							<div class="zs-title">{{ $t('main.certificate') }}{{ index + 1 }}</div>
							<img src="../assets/img/close-icon.png" class="concan" @click="concan(index)" alt="" />
							<div class="certi-add-btn" @click="addCretiPic(index)">
								<img v-if="item.pic" :src="item.pic" alt="">
								<div class="add-btn-icon" v-else>+</div>
								<div class="loading_icon" v-show="load_list[index]">
									<van-loading type="spinner" color="#fff" size="0.24rem" vertical>{{ $t('main.uploading')
									}}</van-loading>
								</div>
							</div>
							<div class="zb-edit-item-content">
								<input type="text" maxlength="20" @keyup="item.content = item.content.replace(/^\s+/g, '')"
									:placeholder="$t('main.peacename')" v-model="item.content" />
							</div>
						</div>
						<div class="add-btn" @click="addBtn" v-if="ds_data.certificate_info.length <= 9">
							<span class="add-icon">+</span><span>{{ $t('main.newcer') }}</span>
						</div>

					</div>
					<div class="zb-pic zb-edit-item" style="margin-top:0.2rem">
						<div class="zb-edit-item-top">
							<div class="title-ltfe">
								<div class="ds-left-icon test-ds-left">
									<img src="../assets/img/ds-bj-icon1.png" alt="">
									机构入驻申请表(法人签名并盖公章)
								</div>
							</div>
							<div class="title-right">{{ $t('main.uilt') }}
								<span class="red">{{ $t('main.required') }}</span>
							</div>
						</div>
						<div class="">
							<div class="sc-btn">

								<div class="sc-btn-item" @click="upLoadImgV(1)">
									<div v-if="!ds_data.apply_inst">+</div>
									<img v-else :src="appPic.url" alt="" />
									<div v-show="appPic.status == 'loading'" class="loading_i">
										<van-loading type="spinner" color="#fff" size="0.24rem" vertical>{{
											$t('main.uploading') }}</van-loading>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="zb-pic zb-edit-item" style="margin-top:0.2rem">
						<div class="zb-edit-item-top">
							<div class="title-ltfe">
								<div class="ds-left-icon test-ds-left">
									<img src="../assets/img/ds-bj-icon1.png" alt="">
									机构营业执照(复印件加盖公章)
								</div>
							</div>
							<div class="title-right">{{ $t('main.uilt') }}
								<span class="red">{{ $t('main.required') }}</span>
							</div>
						</div>
						<div class="">
							<div class="sc-btn">

								<div class="sc-btn-item" @click="upLoadImgV(2)">
									<div v-if="!ds_data.business_inst">+</div>
									<img v-else :src="busPic.url" alt="" />
									<div v-show="busPic.status == 'loading'" class="loading_i">
										<van-loading type="spinner" color="#fff" size="0.24rem" vertical>{{
											$t('main.uploading') }}</van-loading>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="zb-pic zb-edit-item" style="margin-top:0.2rem">
						<div class="zb-edit-item-top">
							<div class="title-ltfe">
								<div class="ds-left-icon test-ds-left" >
									<img style="vertical-align: middle;" src="../assets/img/ds-bj-icon1.png" alt="">
									绑定管理员身份证（正反面）
									<span class="title-right" style="color: #9e9e9e;float: right;">{{ $t('main.uilt') }}
										<span class="red">{{ $t('main.required') }}</span>
									</span>
								</div>
								
							</div>
							
						</div>
						<div class="" style="display:flex;align-items:center;">
							<div class="sc-btn" style="flex-direction:column;align-items:center">

								<div class="sc-btn-item" @click="upLoadImgV(3)">
									<div v-if="!ds_data.id_card_front">+</div>
									<img v-else :src="id_front.url" alt="" />
									<div v-show="id_front.status == 'loading'" class="loading_i">
										<van-loading type="spinner" color="#fff" size="0.24rem" vertical>{{
											$t('main.uploading') }}</van-loading>
									</div>
								</div>
								<div style="color: rgb(158, 158, 158);font-size:0.12rem;margin-top:0.06rem">正面</div>
							</div>
							<div class="sc-btn" style="flex-direction:column;align-items:center;margin-left:0.15rem">
								<div class="sc-btn-item" @click="upLoadImgV(4)">
									<div v-if="!ds_data.id_card_back">+</div>
									<img v-else :src="id_back.url" alt="" />
									<div v-show="id_back.status == 'loading'" class="loading_i">
										<van-loading type="spinner" color="#fff" size="0.24rem" vertical>{{
											$t('main.uploading') }}</van-loading>
									</div>
								</div>
								<div style="color: rgb(158, 158, 158);font-size:0.12rem;margin-top:0.06rem">反面</div>
							</div>
						</div>
					</div>
					<div class="zb-pic zb-edit-item" style="margin-top:0.15rem">
						<div class="zb-edit-item-top">
							<div class="title-ltfe">
								<div class="ds-left-icon">
									<img src="../assets/img/ds-bj-icon2.png" alt="">
									绑定管理员电话号码
								</div>
							</div>
							<div class="title-right"><span class="red">{{ $t('main.required')
							}}</span></div>
						</div>
						<div class="zb-edit-item-bottom">
							<input type="tel" :maxlength="13" 
							placeholder="绑定管理员电话号码" v-model="ds_data.phone" />
						</div>
						<div class="err" v-show="key_test == 'phone'">{{ $t('main.csenword') }}{{ mgc }}</div>
					</div>

					<!-- <div class="agree-box1" v-show="is_xy_show">
							<input @input="checkedFlag1" type="checkbox" value="" id="agree-select1" :checked="isKnow" />
							<label id="" for="agree-select1"> {{$t('main.agreeC')}} </label>
							<span class="blue_xy" @click.stop="showContent">《{{xy.title}}》</span>
					</div> -->
					<div class="" style="display: flex;justify-content: space-between;align-items: center;">
						<div class="yulan_btn" @click="preview">
							{{ $t('main.preview') }}
						</div>
						<div class="submint-info" @click="tijiao">{{ $t('main.submit') }}</div>
					</div>
				</div>
			</div>

		</div>

		<div class="pop" v-if="popFlag">
			<div class="pop-in success-pop">
				<div class="header-title">{{ $t('main.subtips') }}</div>
				<div class="content-bottom">{{ $t('main.modifySucc') }}</div>
				<div class="sure" @click="hidePop">{{ $t('main.determine') }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Dialog } from 'vant';
export default {
	data() {
		return {
			ds_data: {
				name: "",
				pic: "",
				phone:"",
				content: "",
				honor: "",
				certificate_info: [{
					pic: "",
					content: "",
				}],
				apply_inst: "",
				business_inst: "",
				id_card_back: "",
				id_card_front:"",
				birthday:''
			},
			showPic: {
				url: '',
				status: ''
			},
			appPic: {
				url: '',
				status: ''
			},
			busPic: {
				url: '',
				status: ''
			},
			id_front: {
				url: '',
				status: ''
			},
			id_back: {
				url: '',
				status: ''
			},
			token: "",
			popFlag: false,
			isKnow: true,
			xy: {
				content: '',
				title: ''
			},
			mgc: '',
			is_xy_show: false,
			key_test: '',
			old_data: {},
			is_cg: false,
			load_list: [false],
			isback: true,
			birthdayLeangth: 0,
			audio_type: 0,
			draftSasucc: "",
			old_data:{}
		};
	},

	created() {


	},
	// activated() {
	// 	window.addEventListener('popstate', ()=>{this.disableBrowserBack()}, false);
	// 	history.pushState(null, null, document.URL);
	// },
	// deactivated() {

	// 	window.removeEventListener('popstate', ()=>{this.disableBrowserBack()}, false);
	// },

	mounted() {
		this.draftSasucc = this.$t('main.draftSasucc')
		this.loadData();
		this.wechatConfig();
		// window.addEventListener('popstate',this.disableBrowserBack, false);
	},
	beforeRouteLeave(to, from, next) {
		if (to.name == 'master_col' || to.name == 'preview') {
			if (this.isback) {
				this.disableBrowserBack()
			}
		}
		next();
	},
	methods: {
		changeDate() {
			weui.datePicker({
				id: 'last', title: this.$t('main.createTime'), start: 1900, end: new Date(), onConfirm: (value) => {
					this.birthdayLeangth = this.getAge(value[0].value + '/' + value[1].value + '/' + value[2].value)
					this.ds_data.birthday = value[0].value + '-' + value[1].value + '-' + value[2].value
				}
			})
		},
		getAge(val) {
			let currentYear = new Date().getFullYear() //当前的年份
			let calculationYear = new Date(val).getFullYear() //计算的年份
			let wholeTime = currentYear + val.substring(4) //周岁时间
			const calculationAge = currentYear - calculationYear //按照年份计算的年龄

			wholeTime = wholeTime.replace(/-/g, '/')
			//判断是否过了生日
			if (new Date().getTime() > new Date(wholeTime).getTime()) {
				return calculationAge
			} else {
				return calculationAge - 1
			}
		},
		disableBrowserBack() {
			let param = {
				...this.ds_data
			}
			param.certificate_info =  param.certificate_info.filter((item) => {
				return item.pic != "" || item.content != ""
			})

			let flag = 0
			Object.keys(param).forEach(item => {
				if (JSON.stringify(param[item]) != JSON.stringify(this.old_data[item])) {

					flag = 1
				}
			});

			if (!flag) {
				// this.$router.replace('/home_inst')
				return
			}
			this.editData(1)
		},
		// fanhui(){
		// 	this.disableBrowserBack()
		// },
		async loadData() {
			this.axios
				.post("/artist/get", {
					type: 2
				})
				.then((res) => {

					if (res.data.data != "") {

						this.isKnow = true;
						this.old_data = res.data.data
						this.ds_data.name = res.data.data.name;
						this.ds_data.birthday =  res.data.data.birthday ? res.data.data.birthday.split(" ")[0] : '';
						this.ds_data.phone = res.data.data.phone
						this.ds_data.pic = res.data.data.pic;
						this.ds_data.content = res.data.data.content;
						this.ds_data.honor = res.data.data.honor;
						this.ds_data.apply_inst = res.data.data.apply_inst;
						this.ds_data.business_inst = res.data.data.business_inst;
						this.ds_data.id_card_front = res.data.data.id_card_front;
						this.ds_data.id_card_back = res.data.data.id_card_back;
						this.ds_data.certificate_info = res.data.data.certificate_info ? res.data.data.certificate_info : [{
							pic: "",
							content: "",
						}]
						this.audio_type = res.data.data.audio_type
						for (let i = 0; i < this.ds_data.certificate_info.length; i++) {
							this.load_list.push(false);
						}
						
						res.data.data.certificate_info = res.data.data.certificate_info ? res.data.data.certificate_info : []
						this.old_data = JSON.parse(JSON.stringify(res.data.data));
						this.$nextTick(()=>{
								if(this.ds_data.birthday != ''){
									this.birthdayLeangth = this.getAge(this.ds_data.birthday)
								}
						})
						this.showPic.url = res.data.data.pic ? res.data.data.pic.replace(/((^[A-Za-z]{4,5})+\:)/, location.protocol) : '';
						this.appPic.url = res.data.data.apply_inst
						this.busPic.url = res.data.data.business_inst
						this.id_front.url = res.data.data.id_card_front
						this.id_back.url = res.data.data.id_card_back 
						this.is_cg = res.data.data.status == 4 ? true : false;
						if (res.data.data.opinion && res.data.data.status == 0) {
							weui.alert(res.data.data.opinion, { buttons: [{ label: this.$t('main.confIrm') }] })
						}

					}
				})
				.catch((err) => {

				});
			let newdata = ''
			await this.axios.post('common/encrypt',{type:1 + (this.$i18n.locale == 'en' ? 6 : 0)}).then((res)=>{
				newdata = res.data.data.data_encrypted
			})
			this.axios.post('/agreement/detail', { data:newdata }).then((res) => {


				if (!res.data.data.title || !res.data.data.content) {
					this.isKnow = true
					this.is_xy_show = false
					return
				}
				this.is_xy_show = true
				this.xy.title = res.data.data.title
				this.xy.content = res.data.data.content
			})
		},
		tijiao() {
			// if (!this.isKnow) {
			// 	weui.alert(this.$t('main.paoaf'))
			// 	return
			// }

			for (let i = 0; i < this.load_list.length; i++) {
				if (this.load_list[i]) {
					weui.alert(this.$t('main.lpssu'), { buttons: [{ label: this.$t('main.confIrm') }] })
					return
				}
			}
			let certificate_info = this.ds_data.certificate_info.filter((item) => {
				return item.pic || item.content
			})
			for (let i = 0; i < certificate_info.length; i++) {
				if (!certificate_info[i].pic || !certificate_info[i].content) {
					weui.alert(this.$t('main.ceandnameup'), { buttons: [{ label: this.$t('main.confIrm') }] });
					return;
				}
			}

			if (!this.ds_data.name || !this.ds_data.content || !this.ds_data.pic || !this.ds_data.apply_inst || !this.ds_data.business_inst || !this.ds_data.id_card_back || !this.ds_data.id_card_front|| !this.ds_data.phone) {
				weui.alert(this.$t('main.iipcfi'), { buttons: [{ label: this.$t('main.confIrm') }] })
			} else {
				if (this.audio_type == 2) {
					if(this.old_data.name != this.ds_data.name || this.old_data.content != this.ds_data.content ){
						weui.alert(this.$t('main.aessumit'), {
						buttons: [{
							label: this.$t('main.cancel'),
							type: 'primary',
							onClick: () => {

							}
						}, {
							label: this.$t('main.determine'),
							onClick: () => {
								setTimeout(() => {
									weui.alert(this.$t('main.aysutosub'), {
										buttons: [{
											label: this.$t('main.cancel'),
											type: 'primary',
											onClick: () => {

											}
										}, {
											label: this.$t('main.determine'),
											onClick: () => {
												this.editData()
											}
										}]
									})
								}, 500)
							}
						}]
					})
					}else{
						weui.alert(this.$t('main.aysutosub'), {
										buttons: [{
											label: this.$t('main.cancel'),
											type: 'primary',
											onClick: () => {

											}
										}, {
											label: this.$t('main.determine'),
											onClick: () => {
												this.editData()
											}
										}]
									})
					}
					return
				}
				weui.alert(this.$t('main.aysutosub'), {
					buttons: [{
						label: this.$t('main.cancel'),
						type: 'primary',
						onClick: () => {

						}
					}, {
						label: this.$t('main.determine'),
						onClick: () => {
							this.editData()
						}
					}]
				})

			}
		},
		preview() {
			let param = {
				...this.ds_data
			}
			param.certificate_info =  param.certificate_info.filter((item) => {
				return item.pic != "" || item.content != ""
			})
			localStorage.setItem('ds_data', JSON.stringify(param))
			// this.$router.push(`/preview?status=1`);
			this.$router.push(`/preview?status=1&type=2`);
			// this.$router.push({name:'preview',params:{status:1,type:2}});
		},
		editData(index = 0) {

			let param = {
				type: 2,
				...this.ds_data,
				is_init: 1

			}
			param.certificate_info =  param.certificate_info.filter((item) => {
				return item.pic != "" || item.content != ""
			})

			if (index == 1) {
				param.certificate_info = this.ds_data.certificate_info.filter((item) => {
					return item.pic || item.content
				})
			} else {
				this.isback = false
			}

			let flag = 0
			Object.keys(param).forEach(item => {
				console.log(JSON.stringify(param[item]), JSON.stringify(this.old_data[item]))
				if (JSON.stringify(param[item]) != JSON.stringify(this.old_data[item]) && item != 'type') {

					flag = 1
				}
			});
			if (this.is_cg) {
				flag = 1
			}
			if (!flag) {
				setTimeout(() => {
					weui.alert(this.$t('main.inm'), { buttons: [{ label: this.$t('main.confIrm') }] })
				}, 500)
				return
			}
			if (index == 1) {
				Object.keys(param).forEach(item => {
					if (param[item] == '' || param[item] == null) {
						delete param[item]
					}
				});
				param.status = 4
			} else {
				param.audio_type = 1
			}

			this.axios
				.post("/artist/save", param).then((res) => {

					if (res.data.code == 0) {
						if (index == 1) {
							weui.toast(this.draftSasucc, 3000)
							setTimeout(() => {
								// 	this.$router.replace('/home_inst')
							}, 3000)
							return
						}
						this.axios.post('/artist/transArtistInfo', { type: 2 })
						weui.toast(this.$t('main.smipw'), 3000)

						setTimeout(() => {
							this.$router.replace('/home_inst')
						}, 3000)
					} else {

						setTimeout(() => {
							if (index == 1) {

								weui.alert(res.data.msg, { buttons: [{ label: this.$t('main.confIrm'), onClick: () => { this.$router.push('/home_inst') } }] })
								return
							}
							if (res.data.msg.indexOf('敏感词') != -1) {
								weui.alert(this.$t('main.submitmg', { buttons: [{ label: this.$t('main.confIrm') }] }))
								let msg = res.data.msg
								this.key_test = msg.split(' ')[0]
								this.mgc = msg.split('：')[1]
								return
							}
							weui.alert(res.data.msg, { buttons: [{ label: this.$t('main.confIrm') }] })
						}, 500)

					}
				})
		},
		checkedFlag1() {
			this.isKnow = !this.isKnow
		},
		wechatConfig() {


			let url = location.protocol + '//' + location.host



			this.axios
				.post("/auth/wechatConfig", {
					url: url + this.$store.state.jssdkUrl,
					jsApiList: ["chooseImage", "getLocalImgData"],
				})
				.then((res) => {

					let json = JSON.parse(res.data.data.data);

					wx.config({
						debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
						appId: json.appId, // 必填，公众号的唯一标识
						timestamp: json.timestamp, // 必填，生成签名的时间戳
						nonceStr: json.nonceStr, // 必填，生成签名的随机串
						signature: json.signature, // 必填，签名
						jsApiList: json.jsApiList, // 必填，需要使用的 JS 接口列表
					})

				})
		},
		upLoadImg() {
			let _this = this

			wx.chooseImage({
				count: 1, // 默认9
				sizeType: ["compressed"], // 可以指定是原图还是压缩图，默认二者都有
				sourceType: ["album"], // 可以指定来源是相册还是相机，默认二者都有
				success: (res) => {


					var localIds = res.localIds; // 返回选定照片的本地 ID 列表，localId可以作为 img 标签的 src 属性显示图片
					this.showPic.status = 'loading'
					this.showPic.url = 'http://t.6a.com/87/01193881f683a494.png'.replace(/((^[A-Za-z]{4,5})+\:)/, location.protocol)
					wx.getLocalImgData({
						localId: localIds[0], // 图片的localID
						success: (res) => {
							var localData = res.localData; // localData是图片的base64数据，可以用 img 标签显示






							var canvas = document.createElement('canvas')
							var ctx = canvas.getContext('2d')
							var img = new Image();
							if (localData.indexOf(',') == -1) {
								localData = 'data:image/jpg;base64,' + localData
							}
							this.showPic.url = localData
							img.onload = () => {
								let image1
								if (img.width > 1024 || img.height > 1024) {
									if (img.width > img.height) {
										let wh = img.width / img.height
										canvas.width = 1024
										canvas.height = 1024 / wh
										ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
										let piccc = canvas.toDataURL("image/png")
										image1 = this.dataURLtoBlob(piccc);
									} else {
										let hw = img.height / img.width
										canvas.width = 1024 / hw
										canvas.height = 1024
										ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
										let piccc = canvas.toDataURL("image/png")
										image1 = this.dataURLtoBlob(piccc);
									}
								} else {
									image1 = this.dataURLtoBlob(localData);
								}

								let param = new FormData();
								param.append("file", image1, 'test.png', {
									headers: {
										"Content-Type": "multipart/form-data",
									},
								});

								this.axios.post("/common/uploadImg", param).then((res) => {
									if (res.data.code == 0) {
										_this.ds_data.pic = res.data.data.url
										this.showPic.status = 'done'
									} else {
										weui.alert('图片太大上传失败', { buttons: [{ label: this.$t('main.confIrm') }] })
									}


								});

							}
							img.onerror = () => {
								let image1 = this.dataURLtoBlob(localData);
								let param = new FormData();
								param.append("file", image1, 'test.png', {
									headers: {
										"Content-Type": "multipart/form-data",
									},
								});

								this.axios.post("/common/uploadImg", param).then((res) => {
									_this.ds_data.pic = res.data.data.url

								});
							}
							img.src = localData









						},
					});
				},
			});
		},

		dataURLtoBlob(dataurl) {
			var arr = dataurl.split(',')
			// 
			var mime = 'image/png'

			var bstr = arr[1] ? atob(arr[1]) : atob(arr[0])
			var n = bstr.length
			var u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new Blob([u8arr], {
				type: mime
			})
		},
		isPc() {
			let userAgentInfo = navigator.userAgent;

			let Agents = ["Android", "iPhone",
				"SymbianOS", "Windows Phone",
				"iPad", "iPod"];
			let flag = true;
			for (let v = 0; v < Agents.length; v++) {
				if (userAgentInfo.indexOf(Agents[v]) > 0) {
					flag = false;
					break;
				}
			}
			return flag;
		},
		showContent() {
			Dialog.alert({ message: this.xy.content, confirmButtonColor: '#2d71d7', confirmButtonText: this.$t('main.confIrm') })
		},
		concan(index) {
			this.ds_data.certificate_info.splice(index, 1);
			this.load_list.splice(index, 1);
		},
		addCretiPic(index) {
			// 从手机相册中选图
			wx.chooseImage({
				count: 1, // 默认9
				sizeType: ["original"], // 可以指定是原图还是压缩图，默认二者都有
				sourceType: ["album"], // 可以指定来源是相册还是相机，默认二者都有
				success: (res) => {

					this.load_list.splice(index, 1, true)
					wx.getLocalImgData({
						localId: res.localIds[0], // 图片的localID
						success: async (res) => {
							let localData = res.localData;
							if (localData.indexOf(",") == -1) {
								localData = "data:image/jpg;base64," + localData;
							}
							var origImg = new Image();
							var img = new Image();
							img.onload = () => {
								try {
									const canvas = document.createElement("canvas");
									const ctx = canvas.getContext("2d");
									canvas.width = parseInt(img.width / 2);
									canvas.height = parseInt(img.height / 2);
									ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
									const piccc = canvas.toDataURL("image/jpeg", 0.5);
									const images1 = this.dataURLtoBlob(piccc);
									console.log(piccc)
									var param1 = new FormData();
									console.log(images1)
									param1.append("file", images1, "test.jpg", {
										headers: {
											"Content-Type": "multipart/form-data",
										},
									});

									setTimeout(() => {
										for (var value of param1.values()) {
											console.log(value);
										}
										this.axios.post("/common/uploadImg", param1).then((res) => {
											this.ds_data.certificate_info[index].pic = res.data.data.url;
											this.load_list.splice(index, 1, false);
										});
									}, 500)

								} catch (error) {
									console.log(error)
								}

							};
							origImg.onload = () => {
								const canvas = document.createElement("canvas");
								const ctx = canvas.getContext("2d");
								canvas.width = origImg.width;
								canvas.height = origImg.height;
								ctx.drawImage(origImg, 0, 0, canvas.width, canvas.height);
								const origpic = canvas.toDataURL("image/jpeg", 0.9);
								console.log(origpic)
								let images = this.dataURLtoBlob(origpic);
								let param = new FormData();
								param.append("file", images, "test.jpg", {
									headers: {
										"Content-Type": "multipart/form-data",
									},
								});
								this.axios.post("/common/uploadImg", param).then((res) => {
									setTimeout(() => {
										img.src = localData;
									}, 500)
								});

							}
							origImg.src = localData;
						},
					});
				},
			});
		},
		addBtn() {
			this.load_list.push(false)
			this.ds_data.certificate_info.push({
				pic: "",
				content: "",
			});
		},
		upLoadImgV(index) {
			let _this = this

			wx.chooseImage({
				count: 1, // 默认9
				sizeType: ["compressed"], // 可以指定是原图还是压缩图，默认二者都有
				sourceType: ["album"], // 可以指定来源是相册还是相机，默认二者都有
				success: (res) => {


					var localIds = res.localIds; // 返回选定照片的本地 ID 列表，localId可以作为 img 标签的 src 属性显示图片
					switch (index) {
						case 1:
							this.appPic.status = 'loading';
							this.appPic.url = 'http://t.6a.com/87/01193881f683a494.png'.replace(/((^[A-Za-z]{4,5})+\:)/, location.protocol);
							break;
						case 2:
							this.busPic.status = 'loading';
							this.busPic.url = 'http://t.6a.com/87/01193881f683a494.png'.replace(/((^[A-Za-z]{4,5})+\:)/, location.protocol);
							break;
						case 3:
							this.id_front.status = 'loading';
							this.id_front.url = 'http://t.6a.com/87/01193881f683a494.png'.replace(/((^[A-Za-z]{4,5})+\:)/, location.protocol);
							break;
						case 4:
							this.id_back.status = 'loading';
							this.id_back.url = 'http://t.6a.com/87/01193881f683a494.png'.replace(/((^[A-Za-z]{4,5})+\:)/, location.protocol);
							break;
					}

					wx.getLocalImgData({
						localId: localIds[0], // 图片的localID
						success: (res) => {
							var localData = res.localData; // localData是图片的base64数据，可以用 img 标签显示






							var canvas = document.createElement('canvas')
							var ctx = canvas.getContext('2d')
							var img = new Image();
							if (localData.indexOf(',') == -1) {
								localData = 'data:image/jpg;base64,' + localData
							}
							switch (index) {
								case 1:
									this.appPic.url = localData
									break;
								case 2:
									this.busPic.url = localData
									break;
								case 3:
									this.id_front.url = localData
									break;
								case 4:
									this.id_back.url = localData
									break;
							}

							img.onload = () => {
								let image1
								if (img.width > 1024 || img.height > 1024) {
									if (img.width > img.height) {
										let wh = img.width / img.height
										canvas.width = 1024
										canvas.height = 1024 / wh
										ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
										let piccc = canvas.toDataURL("image/png")
										image1 = this.dataURLtoBlob(piccc);
									} else {
										let hw = img.height / img.width
										canvas.width = 1024 / hw
										canvas.height = 1024
										ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
										let piccc = canvas.toDataURL("image/png")
										image1 = this.dataURLtoBlob(piccc);
									}
								} else {
									image1 = this.dataURLtoBlob(localData);
								}

								let param = new FormData();
								param.append("file", image1, 'test.png', {
									headers: {
										"Content-Type": "multipart/form-data",
									},
								});

								this.axios.post("/common/uploadImg", param).then((res) => {
									if (res.data.code == 0) {
										switch (index) {
											case 1:
												_this.ds_data.apply_inst = res.data.data.url;
												this.appPic.status = 'done';
												break;
											case 2:
												_this.ds_data.business_inst = res.data.data.url;
												this.busPic.status = 'done';
												break;
											case 3:
												_this.ds_data.id_card_front = res.data.data.url;
												this.id_front.status = 'done';
												break;
											case 4:
												_this.ds_data.id_card_back = res.data.data.url;
												this.id_back.status = 'done';
												break;
										}

									} else {
										weui.alert('图片太大上传失败', { buttons: [{ label: this.$t('main.confIrm') }] })
									}


								});

							}
							img.onerror = () => {
								let image1 = this.dataURLtoBlob(localData);
								let param = new FormData();
								param.append("file", image1, 'test.png', {
									headers: {
										"Content-Type": "multipart/form-data",
									},
								});

								this.axios.post("/common/uploadImg", param).then((res) => {
									switch (index) {
										case 1:
											_this.ds_data.apply_inst = res.data.data.url;

											break;
										case 2:
											_this.ds_data.business_inst = res.data.data.url;

											break;
										case 3:
											_this.ds_data.id_card_front = res.data.data.url;
										case 4:
											_this.ds_data.id_card_back = res.data.data.url;
											break;
									}


								});
							}
							img.src = localData









						},
					});
				},
			});
		},
	},
};
</script>

<style scoped>
.out {
	padding: 0 0.15rem;
	min-height: 100vh;
	box-sizing: border-box;
	box-sizing: border-box;
	background: url(../assets/img/scj-in-bg.png) no-repeat center;
	background-size: cover;
	background-attachment: fixed;
}

.header {
	display: flex;
	justify-content: space-between;
	/* line-height: 0; */
	margin-bottom: 0rem;
	margin-bottom: 0rem;
	margin: 0 -0.15rem;
	padding: 0 0.15rem;
	align-items: center;
	margin-bottom: 0.1rem;
	box-shadow: 0px 5px 15px -7px rgba(0, 0, 0, 0.3);
	padding-bottom: 0.09rem;
	padding-top: 0.05rem;
	background: #FFFFFF;
}

.Title {
	font-size: 0.16rem;
	position: relative;
}

.English {
	color: #d6eedc;
	position: absolute;
	top: 0.1rem;
	left: 0.15rem;
	width: max-content;
}

.red {
	color: #fe3444;
	margin-left: 0.04rem;
}

.Chinese {
	color: #1b6a21;
	/* top: -0.2rem; */
	left: 0;
	position: relative;
	text-align: left;
	font-size: 0.18rem;
}

.cc-wc {
	font-size: 0.1rem;
	color: #e9630a;
}

.right-icon {
	/* width: 1rem; */
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.right-icon img {
	display: block;
	width: 100%;
	height: 100%;
	width: 0.65rem;
}

.zb-edit {
	font-size: 0.16rem;
	padding-bottom: 0.23rem;
}

.zb-edit-item {
	position: relative;
}

.zb-edit-item-top {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	color: #9e9e9e;
	margin-bottom: 0.13rem;
	flex-wrap: wrap;
}

.title-ltfe {

	font-size: 0.15rem;
	color: #343434;
	/* white-space: nowrap; */
}

.title-right {
	font-size: 0.12rem;
	white-space: nowrap;
}

.zb-edit-item-bottom {
	/* box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3); */
	border-radius: 6px;
	margin-bottom: 0.3rem;
}

.zb-edit-item-bottom input {
	height: 0.5rem;
	width: 100%;
	outline: none;

	border-radius: 6px;

	border: none;
	padding-left: 0.1rem;
	/* padding-top: 0.1rem; */
	box-sizing: border-box;
	color: #545454;
	font-size: 0.14rem;
}

.zb-edit-item-bottom textarea {
	width: 100%;
	outline: none;
	margin-bottom: 0.15rem;
	border-radius: 6px;

	border: none;
	padding: 0.1rem;
	box-sizing: border-box;
	resize: none;
	height: 1.97rem;
	font-size: 0.14rem;
	color: #545454;
	font-family: 'AlibabaPuHuiTiBold';
}

.zb-edit-item-bottom .shijian {
	background: white;
	width: 3.45rem;
	padding: 0.1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.zb-edit-item-bottom .shijian img {
	width: 0.15rem;
	height: 0.1rem;
	padding: 0.05rem 0;
}

.zb-edit-item-bottom.birthday-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 0.16rem;
}

.zb-edit-item-radio {
	margin-bottom: 0.3rem;
}

.zb-edit-item-radio label {
	margin-right: 0.1rem;
}

.zb-edit-item-radio input {
	margin-right: 0.1rem;
}

.sc-btn {
	display: flex;
	align-items: flex-end;
}

.sc-btn-item {
	position: relative;
	width: 0.65rem;
	height: 0.65rem;
	line-height: 0.65rem;
	text-align: center;
	border-radius: 0.06rem;
	background: #e5e5e5;
	/* box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3); */
	font-size: 0.25rem;
	margin-left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 0.135rem;
}

.sc-btn-item img {
	width: 0.6rem;
	height: 0.6rem;
	display: block;
	object-fit: contain;
}

.agree-box {
	display: flex;
	align-items: center;
}

.agree-box span {
	font-size: 0.14rem;
	font-weight: 500;
	letter-spacing: 0px;
	color: #9e9e9e;
	margin-left: 0.1rem;
}

.submint-info {
	width: 65%;
	height: 0.5rem;
	line-height: 0.5rem;
	text-align: center;
	color: #ffffff;
	border-radius: 0.05rem;
	background: #2d71d7;
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
	margin-top: 0.23rem;
	margin-bottom: 0.23rem;
}

.pop {
	position: fixed;
	background: rgba(0, 0, 0, 0.5);
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	/* display: none; */
}

.pop .pop-in {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #ffffff;
	font-size: 0.16rem;
	width: 100%;
}

.header-title {
	background-color: #3662ec;
	text-align: left;
	color: #ffffff;
	height: 0.45rem;
	line-height: 0.45rem;
	padding-left: 0.1rem;
}

.content-bottom {
	min-height: 1.2rem;
	text-align: left;
	padding: 0.2rem;
}

.sure {
	width: 90%;
	height: 0.45rem;
	margin: 0 auto;
	line-height: 0.45rem;
	text-align: center;
	color: #ffffff;
	border-radius: 10px;
	background: #3662ec;
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
	margin-top: 0.23rem;
	margin-bottom: 0.23rem;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	/*WebKit browsers*/

	font-size: 0.14rem;

	color: #cbcbcb;
}

input::-moz-input-placeholder,
textarea::-moz-input-placeholder {
	/*Mozilla Firefox*/

	font-size: 0.14rem;

	color: #cbcbcb;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
	/*Internet Explorer*/

	font-size: 0.14rem;

	color: #cbcbcb;
}

.ds-pic {
	display: flex;
	flex-direction: column;
	align-items: baseline;
	margin-bottom: 0.3rem !important;
}

.ds-pic .title-ltfe {
	display: flex;
	align-items: center;
	justify-content: inherit;
	width: 100%;
	justify-content: space-between;
}

.ds-pic .title-ltfe .title-right {
	color: #9e9e9e;
}

.ds-left-icon {
	display: flex;
	align-items: center;
}

.test-edit-list {
	/* width: 1.2rem; */
}

.test-ds-left {
	/* width: 1.55rem; */
}

.ds-left-icon img {
	width: 0.18rem;
	margin-right: 0.1rem;
}

.agree-box1 input {
	display: none;
}

.agree-box1 {
	display: flex;
	align-items: center;
	position: relative;
	margin-top: 0.2rem;
}

.agree-box1 label::before {
	display: inline-block;
	content: "";
	width: 0.175rem;
	height: 0.175rem;
	background-color: #ffffff;
	border-radius: 50%;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	position: absolute;
	border: 1px solid #bfbfbf;
	box-sizing: border-box;
}

.agree-box1 input:checked+label::before {
	display: inline-block;
	content: "";
	width: 0.175rem;
	height: 0.175rem;
	border-radius: 50%;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	background: url(../assets/img/rz-select-iocn.png) no-repeat center;
	background-size: 100% 100%;
}

.agree-box1 label {
	font-size: 0.12rem;
	margin-left: 0.325rem;
	line-height: 0.175rem;
}

.blue_xy {
	color: #2d71d7;
	font-size: 0.12rem;
	line-height: 0.175rem;
}

/* 证书上传图片 */
.creti-box {
	position: relative;
}

.zs-title {
	font-size: 0.14rem;
}

.concan {
	position: absolute;
	right: 0.05rem;
	top: 0;
	width: 24px;
	height: 24px;
}

.certi-add-btn {
	width: 0.65rem;
	height: 0.65rem;
	overflow: hidden;
	background-color: white;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	border-radius: 0.05rem;
	margin: 0.06rem 0 0.10rem;
}

.certi-add-btn img {
	width: 100%;
}

.certi-add-btn .add-btn-icon {
	font-size: 0.3rem;
	color: #CCCCCC;
}

.certi-add-btn .loading_icon {
	position: absolute;
	width: 0.65rem;
	height: 0.65rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: #1989fa;
	font-size: 0.12rem;
	background: rgba(0, 0, 0, 0.6);
}

.zb-edit-item-content {
	/* box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3); */
	border-radius: 6px;
	margin-bottom: 0.12rem;
}

.zb-edit-item-content input {
	height: 0.5rem;
	width: 100%;
	outline: none;

	border-radius: 6px;

	border: none;
	padding-left: 0.1rem;
	/* padding-top: 0.1rem; */
	box-sizing: border-box;
	color: #545454;
	font-size: 0.14rem;
}

.certi-add-btn .zb-edit-item-content textarea {
	width: 100%;
	outline: none;
	margin-bottom: 0.15rem;
	border-radius: 6px;

	border: none;
	padding: 0.1rem;
	box-sizing: border-box;
	resize: none;
	height: 1.97rem;
	font-size: 0.14rem;
	color: #545454;
	font-family: 'AlibabaPuHuiTiBold';
}

.add-btn {
	background-color: white;
	color: #2d71d7;
	width: 100%;
	height: 0.45rem;
	border: 0.01rem solid #2d71d7;
	border-radius: 0.05rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 0;
	line-height: normal;
}

.add-btn .add-icon {
	font-size: 0.25rem;
	margin-right: 0.05rem;
}

.err {
	color: red;
	font-size: 0.15rem;
	position: absolute;
	bottom: -0.26rem;
}

.err1 {
	color: red;
	font-size: 0.15rem;
	position: absolute;
	bottom: -0.16rem;
}

.yulan_btn {
	width: 30%;
	height: 0.5rem;
	line-height: 0.5rem;
	text-align: center;
	color: #ffffff;
	border-radius: 0.05rem;
	background: #aaaa7f;
}

.loading_i {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: #1989fa;
	font-size: 0.12rem;
	background: rgba(0, 0, 0, 0.6);
}

:deep(.van-loading__text) {
	font-size: 0.12rem;
	line-height: 0.12rem;
	margin: 0;
	margin-top: 0.1rem;
}
</style>
